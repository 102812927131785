import React, { useState, useEffect } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import DollarIcon from '@material-ui/icons/AttachMoney'
import useStyles from './contact-styles'

export default function IndividualGrant({ handleClose }) {
  var classes = useStyles()
  const [dob, setDob] = useState(null)
  const [state, setState] = useState({
    reqName: '',
    address: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
    parName: '',
    relation: '',
    pastEvent: false,
    events: '',
    volunteer: false,
    contact: false,
    programName: '',
    description: '',
    explain: '',
    benefit: '',
    otherFunding: false,
    fundingCost: '',
    total: '',
  })

  function isDisabled() {
    let pastEventEmpty = false
    let otherFunding = false
    if (state.pastEvent && state.events === '') {
      pastEventEmpty = true
    }
    if (state.otherFunding && state.fundingCost === '') {
      otherFunding = true
    }
    console.log(state)
    return (
      state.date === '' ||
      state.reqName === '' ||
      state.address === '' ||
      state.state === '' ||
      state.zip === '' ||
      state.email === '' ||
      state.phone === '' ||
      state.parName === '' ||
      state.relation === '' ||
      state.programName === '' ||
      state.description === '' ||
      state.explain === '' ||
      state.benfit === '' ||
      state.total == '' ||
      dob === null ||
      otherFunding ||
      pastEventEmpty
    )
  }

  const handleDOBChange = date => {
    setDob(date)
  }

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  function handleCheck(e) {
    setState({
      ...state,
      [e.target.name]: !state[e.target.name],
    })
  }

  return (
    <>
      <Typography className={classes.heading} variant="h1">
        Individual Grant Form
      </Typography>
      <form
        className={classes.form}
        name="individualGrant"
        method="POST"
        data-netlify="true"
      >
        <TextField
          id="reqName"
          label="Requestor's Name"
          name="reqName"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="address"
          label="Street Address"
          name="address"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="city"
          label="City"
          name="city"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="state"
          label="State"
          name="state"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="zip"
          label="Zip Code"
          name="zip"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="phone"
          label="Phone Number"
          name="phone"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="email"
          label="Email"
          name="email"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="parName"
          label="Participant's Name"
          name="parName"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <KeyboardDatePicker
          inputVariant="filled"
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="dob"
          label="Participant's Date of Birth"
          name="dob"
          value={dob}
          onChange={handleDOBChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          className={classes.input}
          required={true}
          emptyLabel="Please enter the date of birth"
        />
        <TextField
          id="relation"
          label="Relationship to Participant"
          name="relation"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />

        <Typography className={classes.label}>
          <label htmlFor="pastEvent">
            Within the last year, did you attend or volunteer at one of our
            events? Example: the Buddy Walk, Halloween Party, Winter Carnival
            etc.
          </label>
        </Typography>
        <Checkbox id="pastEvent" name="pastEvent" onChange={handleCheck} />

        {state.pastEvent && (
          <>
            <Typography className={classes.label}>
              <label htmlFor="events">If so, which one(s)?</label>
            </Typography>
            <TextField
              id="events"
              name="events"
              multiline
              variant="filled"
              className={classes.input}
              onChange={handleChange}
              required={true}
            />
          </>
        )}

        <Typography className={classes.label}>
          <label htmlFor="volunteer">Would you consider volunteering?</label>
        </Typography>
        <Checkbox id="volunteer" name="volunteer" onChange={handleCheck} />

        {state.volunteer && (
          <>
            <Typography className={classes.label}>
              <label htmlFor="contact">If so, can we contact you?</label>
            </Typography>
            <Checkbox id="contact" name="contact" onChange={handleCheck} />
          </>
        )}

        <Typography className={classes.label}>
          <label htmlFor="programName">
            Name of program or budget request:
          </label>
        </Typography>
        <TextField
          id="programName"
          name="programName"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />

        <Typography className={classes.label}>
          <label htmlFor="description">
            Description of program or budget request:
          </label>
        </Typography>
        <TextField
          id="description"
          name="description"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          multiline
          required={true}
        />

        <Typography className={classes.label}>
          <label htmlFor="explain">
            Explain the reasons for the funding request:
          </label>
        </Typography>
        <TextField
          id="explain"
          name="explain"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          multiline
          required={true}
        />

        <Typography className={classes.label}>
          <label htmlFor="benefit">
            How does this program or budget request benefit the individual with
            Down syndrome?
          </label>
        </Typography>
        <TextField
          id="benefit"
          name="benefit"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          multiline
          required={true}
        />

        <div className={classes.row}>
          <Typography className={(classes.label, { margin: 0 })}>
            <label htmlFor="otherFunding">
              Have you received any other funding for this program?
            </label>
          </Typography>
          <Checkbox
            id="otherFunding"
            name="otherFunding"
            onChange={handleCheck}
          />
        </div>

        {state.otherFunding && (
          <TextField
            id="fundingCost"
            label="Other Funding Cost"
            name="fundingCost"
            type="number"
            variant="filled"
            className={classes.input}
            onChange={handleChange}
            required={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DollarIcon />
                </InputAdornment>
              ),
            }}
          />
        )}

        <TextField
          id="total"
          label="Total Cost"
          name="total"
          type="number"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DollarIcon />
              </InputAdornment>
            ),
          }}
        />

        <input type="hidden" name="form-name" value="individualGrant" />
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          disableFocusRipple
          disabled={isDisabled()}
        >
          Submit
        </Button>
      </form>
    </>
  )
}
